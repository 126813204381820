import React, { useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useIsLoggedIn, useUserActions } from 'store/user';
import { useIsFromUS } from 'store/organizations';

import { getColumns } from './utils';
import Link from './Link';
import MobileSection from './MobileSection';
import { Container, Wrapper, Title, Icon, Column, ColumnTitle } from './styles';

const Footer = ({ className }) => {
  const { t } = useTranslation('app');
  const isLoggedIn = useIsLoggedIn();
  const isFromUS = useIsFromUS();
  const columns = useMemo(() => getColumns(isLoggedIn), [isLoggedIn]);
  const { logout } = useUserActions();
  const handleClick = useCallback(() => logout(), [logout]);

  return (
    <Container className={className}>
      {!isLoggedIn && (
        <Title>
          {t(isFromUS ? 'Alleye is a digital companion' : 'Alleye is the digital companion for your')} <Icon /> {t('health')}.
        </Title>
      )}
      <Wrapper>
        {columns.map(({ id, title, mobileTitle, items }) => (
          <Fragment key={id}>
            <Column>
              <ColumnTitle>{t(title)}</ColumnTitle>
              {items.map(({ to, href, name, isLogout }) => (
                <Link key={to || href || name} to={to} href={href} onClick={isLogout ? handleClick : undefined}>
                  {t(name)}
                </Link>
              ))}
            </Column>
            <MobileSection title={t(mobileTitle || title)}>
              {items.map(({ to, href, name, isLogout }) => (
                <Link key={to || href || name} to={to} href={href} onClick={isLogout ? handleClick : undefined}>
                  {t(name)}
                </Link>
              ))}
            </MobileSection>
          </Fragment>
        ))}
      </Wrapper>
    </Container>
  );
};

Footer.propTypes = { className: PropTypes.string };

export default Footer;
